export default {
  columnsLimit: 6,
  unitType: '%',
  reverseColumns: false,
  dropPlaceholderOptions: {
    className: 'drop-preview',
    animationDuration: '150',
    showOnTop: true
  },
  displayOnlyOnSelect: [
    {
      label: 't_on-mobile_',
      value: 'show-on-mobile'
    },
    {
      label: 't_on-desktop_',
      value: 'show-on-desktop'
    }
  ],
  alert: {
    columnSize: {
      type: null,
      active: false,
      priority: null,
      messageVar: null,
      title: {
        error: 't_edit-panel-locked_',
        info: 't_note_'
      },
      messages: {
        mixtMode: 't_warning-all-field-must-have-custom_',
        minSize: 't_warning-min-size-column_',
        limit: 't_info-reach-max-size_',
        sum: 't_warning-sum-must-be-100_',
        noDuplicate: 't_info-no-duplicate-column_'
      }
    }
  }
}
